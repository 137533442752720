<template>
  <div class="termManage">
    <div>
      <el-button @click="addTableData"
        icon="el-icon-plus"
        type="success">添加
      </el-button>
      <el-table :data="tableData"
        style="width: 100%"
        border>
        <el-table-column label="序号"
          width="60"
          align="center"
          type="index">
        </el-table-column>
        <el-table-column label="学年-学期"
          align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.year }} {{ scope.row.semester_no }}</span>
          </template>
        </el-table-column>
        <el-table-column label="开学时间-放假时间"
          align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.begintime }}至{{ scope.row.endtime }}</span>
          </template>
        </el-table-column>
        <el-table-column label="当前学期"
          align="center">
          <template slot-scope="scope">
            <el-tag :type="scope.row.status === '0' ? 'success' : 'info'">
              {{ scope.row.status === "0" ? "是" : "否" }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column label="创建者"
          align="center"
          prop="opername">
        </el-table-column>
        <el-table-column label="操作"
          align="center"
          width="350px">
          <template slot-scope="scope">
            <el-button size="medium" @click="currentTermChange(scope.row, scope.row.id)"
              v-show="scope.row.status === '1'">设为当前学期
            </el-button>
            <el-button size="medium" @click="modifyTerm(scope.$index)"
              type="primary">修改
            </el-button>
            <el-button size="medium" @click="deleteTerm(scope.$index, scope.row.id)"
              type="danger">删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-dialog :title="currentForm.id === '' ? '添加新学期' : '修改学期'"
        :visible.sync="reviseVisible"
        width="600px">
        <div class="reviseForm-box">
          <el-form label-width="80px"
            :model="currentForm"
            :rules="rules"
            ref="currentForm">
            <el-form-item label="学年度"
              prop="year">
              <el-input v-model="currentForm.year"
                style="width: 220px"></el-input>
            </el-form-item>
            <el-form-item label="学期名称"
              prop="semester_no">
              <el-select v-model="currentForm.semester_no"
                placeholder="请选择学期">
                <el-option label="上学期"
                  value="上学期"></el-option>
                <el-option label="下学期"
                  value="下学期"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="开始时间"
              prop="begintime">
              <el-date-picker placeholder="选择日期"
                v-model="currentForm.begintime"
                value-format="yyyy-MM-dd"></el-date-picker>
            </el-form-item>
            <el-form-item label="结束时间"
              prop="endtime">
              <el-date-picker placeholder="选择日期"
                v-model="currentForm.endtime"
                value-format="yyyy-MM-dd"></el-date-picker>
            </el-form-item>
          </el-form>
          <div slot="footer">
            <el-button @click="reviseVisible = false">取 消</el-button>
            <el-button type="primary"
              @click="modifyConfirm('currentForm', currentForm.id)">确 定
            </el-button>
          </div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
export default {
  name: "termManage",
  data() {
    return {
      tableData: [],
      reviseVisible: false,
      deleteVisible: false,
      currentForm: {
        year: "",
        semester_no: "",
        begintime: "",
        endtime: "",
        id: "",
      },
      rules: {
        year: [
          {
            required: true,
            message: "请输入学年度",
            trigger: "blur",
          },
        ],
        semester_no: [
          {
            required: true,
            message: "请选择学期",
            trigger: "change",
          },
        ],
        begintime: [
          {
            required: true,
            message: "请选择日期",
            trigger: "change",
          },
        ],
        endtime: [
          {
            required: true,
            message: "请选择日期",
            trigger: "change",
          },
        ],
      },
    };
  },
  created() {
    this.loadTermList();
  },
  methods: {
    loadTermList() {
      const that = this;
      this.tableData = [];
      this.$post("/media/queryYearList.do", {})
        .then((res) => {
          for (let i = 0; i < res.data.length; i++) {
            that.tableData.push(res.data[i]);
          }
        })
        .catch((error) => {
          that.$message.error(error.message);
        });
    },
    modifyConfirm(formName, id) {
      const that = this;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let apiUrl = "/media/insertyear.do";
          let data = {
            year: this.currentForm.year,
            semester_no: this.currentForm.semester_no,
            begintime: this.currentForm.begintime,
            endtime: this.currentForm.endtime,
          };
          if (id) {
            data.id = this.currentForm.id;
            apiUrl = "/media/updateYear.do";
          }
          this.$confirm("是否确认执行此操作", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.$post(apiUrl, data)
                .then((res) => {
                  that.reviseVisible = false;
                  that.$message({
                    message: "成功!",
                    type: "success",
                  });
                  that.loadTermList();
                })
                .catch((err) => {
                  that.$message.error(err.message);
                  that.reviseVisible = false;
                });
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消更改",
              });
            });
        }
      });
    },
    currentTermChange(row, id) {
      const that = this;
      this.$confirm("是否改成当前学期", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$post("/media/updateYearStatus.do", { id })
            .then((res) => {
              row.status = "0";
              that.$message({
                type: "success",
                message: "更改成功!",
              });
              that.loadTermList();
            })
            .catch((err) => {
              that.$message({
                type: "warning",
                message: err.message,
              });
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消更改",
          });
        });
    },
    deleteTerm(index, id) {
      const that = this;
      this.$confirm("是否删除当前学期", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$post("/media/deleteyear.do", { id })
            .then((res) => {
              that.tableData.splice(index, 1);
              that.$message({
                type: "success",
                message: "删除成功!",
              });
            })
            .catch((err) => {
              that.$message({
                type: "warning",
                message: err.message,
              });
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    addTableData() {
      this.reviseVisible = true;
      this.$nextTick(() => {
        this.$refs["currentForm"].resetFields();
        this.currentForm.id = "";
      });
    },
    modifyTerm(index) {
      this.reviseVisible = true;
      this.$nextTick(() => {
        this.currentForm.id = this.tableData[index].id;
        this.currentForm.year = this.tableData[index].year;
        this.currentForm.semester_no = this.tableData[index].semester_no;
        this.currentForm.begintime = this.tableData[index].begintime;
        this.currentForm.endtime = this.tableData[index].endtime;
      });
    },
  },
};
</script>

<style scoped lang="scss">
.reviseForm-box {
  width: 400px;
  margin: 0 auto;
}
</style>
